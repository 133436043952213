import React, { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';

const Login = React.lazy(() => import("./components/login/login.tsx"));
const Error = React.lazy(() => import("./components/error/error.tsx"));
const Index = React.lazy(() => import("./components/index/index.tsx"));
const Search = React.lazy(() => import("./components/search/search.tsx"));
const Dashboard = React.lazy(() => import("./components/dashboard/dashboard.tsx"));
const Post = React.lazy(() => import("./components/post/post.tsx"));
const Category = React.lazy(() => import("./components/category/category.tsx"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
    errorElement: <Error />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/search",
        element: <Search />,
      },
      {
        path: "/post",
        element: <Post />,
      },
      {
        path: "/category",
        element: <Category />,
      },
      {
        path: "*",
        element: <Error />,
      }
    ]
  }
]);

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Suspense fallback={<div>loading</div>}>
      <RouterProvider router={router} />
    </Suspense>
  </StrictMode>
)
